import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
/* custom Hooks and stylesheet */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.scss';
import './assets/lib/fontawesome/css/all.css';
import reportWebVitals from './reportWebVitals';

// Components
const Landing = lazy(() => import("./pages/Landing/Landing"));
const Loader = lazy(() => import("./pages/Loader/Loader"));
const Notfound = lazy(() => import("./pages/Notfound/Notfound"));
const Connexion = lazy(() => import("./pages/Auth/Connexion/Connexion"));
const ConnexionError = lazy(() => import("./pages/Auth/Connexion/ConnexionError"));
const Inscription = lazy(() => import("./pages/Auth/Inscription/Inscription"));
const ConfirmMail = lazy(() => import("./pages/Auth/Inscription/ConfirmMail"));
const ConfirmToken= lazy(() => import("./pages/Auth/Inscription/ConfirmToken"));
const Galerie = lazy(() => import("./pages/GalerieTemplates/GalerieTemplates"));
const Communication1 = lazy(() => import("./Templates/Communication/communication1/comm1"));
const Banques = lazy(() => import("./Templates/Finances/Banques/comm2"));
const Felicitation = lazy(() => import("./pages/Felicitation/Felicitation"));
const HomeExposant = lazy(() => import("./pages/Home/Exposant/Exposant"));
const HomeOperateur = lazy(() => import("./pages/Home/Operateur/Operateur"));
const Reservations = lazy(() => import("./pages/Reservations/Reservations"));
const CreerStand = lazy(() => import("./pages/CreerStand/CreerStand"));
const CreerStand2 = lazy(() => import("./pages/CreerStand/CreerStand2"));
const CreerStand3 = lazy(() => import("./pages/CreerStand/CreerStand3"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const GererStand = lazy(() => import("./pages/GererStand/GererStand"));
const AdminLogin = lazy(() => import("./pages/Admin/Login"));
const AdminDashboard = lazy(() => import("./pages/Admin/Dashboard/Dashboard"));
const AdminGestionOp = lazy(() => import("./pages/Admin/GestionOperateur/GestionOp"));
const AdminListeOp = lazy(() => import("./pages/Admin/Listeoperateurs/ListeOp"));
const Ecolesups = lazy(() => import("./Templates/Ecolesups"));
const Economies = lazy(() => import("./Templates/stands/Economies"));
const Droits = lazy(() => import("./Templates/stands/Droits"));
const Litteratures = lazy(() => import("./Templates/stands/Litteratures"));
const Sciences = lazy(() => import("./Templates/stands/Sciences"));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={ <Loader/> }>
        <HelmetProvider>
          <Routes>
            <Route path="/*" index element={<Landing/>} />
            <Route path="/loader" element={<Loader/>} />
            <Route path='*' element={<Notfound/>} />
            <Route path='/connexion' element={<Connexion/>} />
            <Route path='/connexion/error' element={<ConnexionError/>} />
            <Route path='/inscription' element={<Inscription/>} />
            <Route path="/confirm-email/:email" element={<ConfirmMail/>} />
            <Route path="/confirm-email/token/:token" element={<ConfirmToken/>} />
            <Route path='/galerie' element={<Galerie/>} />
            <Route path="/templates/ecole_sup" element={<Ecolesups/>} />
            <Route path="/templates/comm1" element={<Communication1/>} />
            <Route path="/templates/banques" element={<Banques/>} />
            <Route path="/felicitation" element={<Felicitation/>} />
            <Route path="/home/Exposant" element={<HomeExposant/>} />
            <Route path="/home/Operateur" element={<HomeOperateur/>} />
            <Route path="/reservations" element={<Reservations/>} />
            <Route path="/creerStand" element={<CreerStand/>} />
            <Route path="/creerStand2" element={<CreerStand2/>} />
            <Route path="/creerStand3" element={<CreerStand3/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/gererstand" element={<GererStand/>} />
            <Route path="/admin" element={<AdminLogin/>} />
            <Route path="/admin/dashboard" element={<AdminDashboard/>} />
            <Route path="/admin/gestion-operateur" element={<AdminGestionOp/>} />
            <Route path="/admin/liste-operateur" element={<AdminListeOp/>} />
            <Route path="/templates/stand/economies_et_gestions" element={<Economies/>} />
            <Route path="/templates/stand/droits" element={<Droits/>} />
            <Route path="/templates/stand/literratures" element={<Litteratures />} />
            <Route path="/templates/stand/sciences" element={<Sciences />} />
          </Routes>
        </HelmetProvider>
      </Suspense>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
